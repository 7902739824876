import React from "react";
import Fade from "react-reveal/Fade";
import "./Fifth.css";

function Fifth() {
  return (
    <div>
      <Fade delay={5000}>
        <a href={"http://progate.com"}>click me</a>
      </Fade>
    </div>
  );
}

export default Fifth;
