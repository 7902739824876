import React from "react";
import Fade from "react-reveal/Fade";
import "./First.css";

function First() {
  return (
    <div>
      <Fade delay={1000}>
        <h1 className="First">Congrats Nainai</h1>
      </Fade>
    </div>
  );
}

export default First;
