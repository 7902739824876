import React from "react";
import Fade from "react-reveal/Fade";
import "./Second.css";

function Second() {
  return (
    <div>
      <Fade delay={2000}>
        <h1 className="Second">You did it!</h1>
      </Fade>
    </div>
  );
}

export default Second;
