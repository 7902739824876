import React from "react";
import Fade from "react-reveal/Fade";
import "./Fourth.css";

function Fourth() {
  return (
    <div>
      <Fade delay={4000}>
        <h1 className="Fourth">And i just have the perfect gift for you</h1>
      </Fade>
    </div>
  );
}

export default Fourth;
