import React from "react";
import Fade from "react-reveal/Fade";
import "./Third.css";
function Third() {
  return (
    <div>
      <Fade delay={3000}>
        <h1 className="Third">You almost done during First Semester</h1>
      </Fade>
    </div>
  );
}

export default Third;
