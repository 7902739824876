import "./App.css";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import First from "./components/First/First";
import Second from "./components/Second/Second";
import Third from "./components/Third/Third";
import Fourth from "./components/Fourth/Fourth";
import Fifth from "./components/Fifth/Fifth";

function App() {
  const { width, height } = useWindowSize();
  return (
    <div className="App">
      <Confetti
        width={width}
        height={height}
        recycle={false}
        numberOfPieces={2000}
      />
      <First />
      <Second />
      <Third />
      <Fourth />
      <Fifth />
    </div>
  );
}

export default App;
